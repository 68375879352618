import "./Services.scss";
import HomeNavbar from "../../../components/homeNavbar/HomeNavbar";
import HomeFooter from "../../../components/homeFooter/HomeFooter";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";


const Services = ({ active }) => {

    const navigate = useNavigate();
    return (
        <div>
            <div className="homeContainer">
                <Helmet>
                    <title>TactiBots | Where Bussinesses Learn, Share & Grow</title>
                    <meta name="description" content="TactiBots is a FREE tool to monitor multiple e-commerce platform." />
                    <meta name="og:description" content="TactiBots is a FREE tool to monitor multiple e-commerce platform." />
                    <meta property="og:image" content="https://tactibot.com/static/media/logo_background.3e6fe6b035d735b3db9e.png" />
                </Helmet>
                <HomeNavbar active={active} />
                <div className="servicesContainer" name="servicesContainer">
                    <h2>Cloud Services</h2>
                        <br></br>
                        <h3>is the latest product TactiBots had to offer.
                        <br></br>As of now, it's all FREE but we do have a limitation on the number of Orders and Stores provided.</h3>
                        <img className="preview"
                            src={require('../../../materials/login_background.png')}
                            alt=""
                        />
                        {
                            /*
                    <div className="sections">
                        <div className="cloudColumns cloudTitleColumns">
                            <ul className="price">
                                <li className="header">
                                    <h1>Cloud Services</h1>
                                </li>
                                <li>Orders</li>
                                <li>Store Quantity</li>
                                <li>Shipment</li>
                                <li>Payment Reconciliation</li>
                                <li>Reports</li>
                            </ul>
                        </div>
                        <div className="cloudColumns">
                            <ul className="price">
                                <li className="header">
                                    <h1>Free</h1>
                                    <p>Free</p>
                                    <br></br>
                                    
                                    <button onClick={(() => { navigate("/login");})}>Sign Up</button>
                                </li>
                                <li><p className="innerTitle">Orders : </p>3,000 / month</li>
                                <li><p className="innerTitle">Store Quantity : </p>10</li>
                                <li>
                                    <p className="innerTitle">Shipment : </p>
                                    <img
                                        src={require('../../../materials/correct.png')}
                                        alt=""
                                    />
                                </li>
                                <li>
                                    <p className="innerTitle">Payment Reconciliation : </p>
                                    <img
                                        src={require('../../../materials/correct.png')}
                                        alt=""
                                    />
                                </li>
                                <li>
                                    <p className="innerTitle">Reports : </p>
                                    <img
                                        src={require('../../../materials/correct.png')}
                                        alt=""
                                    />
                                </li>
                            </ul>
                        </div>
                    </div>
                            */
                        }
                    <div className="sections">
                        <h2>Windows Application</h2>
                        <br></br>
                        <h3>is a Desktop Application integrated with E-Commerce features.
                        <br></br>
                        We can help with detecting abnormal orders, accounting and more.</h3> 
                        <img className="preview"
                            src={require('../../../materials/windows_application.png')}
                            alt=""
                        />
                        <br></br>
                    
                        <div className="windowsColumns windowsTitleColumns">
                            <ul className="price">
                                <li className="header">
                                    <h1>Windows Application</h1>
                                </li>
                                <li>Orders</li>
                                <li>Store Quantity</li>
                                {
                                    /*
                                <li>Shipment (Shopee Only)</li>
                                <li>Inventory Sync</li>
                                    */
                                }
                                <li>Payment Reconciliation</li>
                                {
                                    /*
                                <li>Multi Chat</li>
                                    */
                                }
                                <li>Invoice Entry</li>
                                <li>Contra Invoice</li>
                                <li>Monthly Accounting</li>
                                <li>SQL Integration</li>
                                <li>Accounting Software</li>
                            </ul>
                        </div>
                        {
                            /*
                        <div className="windowsColumns">
                            <ul className="price">
                                <li className="header">
                                    <h1>Basic</h1>
                                    <p>RM 0.10 / order</p>
                                    <br></br>
                                    <button onClick={(() => { 
                                        window.open('https://wa.link/u8m2if');
                                    })}>Sign Up</button>
                                </li>
                                <li><p className="innerTitle">Orders : </p>Unlimited</li>
                                <li><p className="innerTitle">Store Quantity : </p>Unlimited</li>
                                <li><p className="innerTitle">Shipment (Shopee Only) : </p>
                                    <img
                                        src={require('../../../materials/correct.png')}
                                        alt=""
                                    />
                                </li>
                                <li><p className="innerTitle">Inventory Sync : </p>
                                    <img
                                        src={require('../../../materials/correct.png')}
                                        alt=""
                                    />
                                </li>
                                <li><p className="innerTitle">Payment Reconciliation : </p>
                                    <img
                                        src={require('../../../materials/correct.png')}
                                        alt=""
                                    />
                                </li>
                                <li><p className="innerTitle">Multi Chat : </p>
                                    <img
                                        src={require('../../../materials/correct.png')}
                                        alt=""
                                    />
                                </li>
                                <li><p className="innerTitle">Invoice Entry : </p>
                                    <img
                                        src={require('../../../materials/wrong.png')}
                                        alt=""
                                    />
                                </li>
                                <li><p className="innerTitle">Contra Invoice : </p>
                                    <img
                                        src={require('../../../materials/wrong.png')}
                                        alt=""
                                    />
                                </li>
                                <li><p className="innerTitle">Monthly Accounting : </p>
                                    <img
                                        src={require('../../../materials/wrong.png')}
                                        alt=""
                                    />
                                </li>
                                <li><p className="innerTitle">SQL Integration : </p>
                                    <img
                                        src={require('../../../materials/wrong.png')}
                                        alt=""
                                    />
                                </li>
                                <li><p className="innerTitle">Accounting Software : </p>
                                    <img
                                        src={require('../../../materials/wrong.png')}
                                        alt=""
                                    />
                                </li>
                            </ul>
                        </div>
                            */
                        }
                        <div className="windowsColumns">
                            <ul className="price">
                                <li className="header">
                                    <h1>Accounting</h1>
                                    <p>RM 0.10 / order</p>
                                    <br></br>
                                    <button onClick={(() => { 
                                        window.open('https://wa.link/5mzvzc');
                                    })}>Sign Up</button>
                                </li>
                                <li><p className="innerTitle">Orders : </p>Unlimited</li>
                                <li><p className="innerTitle">Store Quantity : </p>Unlimited</li>
                                {
                                    /*
                                <li><p className="innerTitle">Shipment (Shopee Only) : </p>
                                    <img
                                        src={require('../../../materials/wrong.png')}
                                        alt=""
                                    />
                                </li>
                                <li><p className="innerTitle">Inventory Sync : </p>
                                    <img
                                        src={require('../../../materials/wrong.png')}
                                        alt=""
                                    />
                                </li>
                                    */
                                }
                                <li><p className="innerTitle">Payment Reconciliation : </p>
                                    <img
                                        src={require('../../../materials/correct.png')}
                                        alt=""
                                    />
                                </li>
                                {
                                    /*
                                <li><p className="innerTitle">Multi Chat : </p>
                                    <img
                                        src={require('../../../materials/wrong.png')}
                                        alt=""
                                    />
                                </li>
                                    */
                                }
                                <li><p className="innerTitle">Invoice Entry : </p>
                                    <img
                                        src={require('../../../materials/correct.png')}
                                        alt=""
                                    />
                                </li>
                                <li><p className="innerTitle">Contra Invoice : </p>
                                    <img
                                        src={require('../../../materials/correct.png')}
                                        alt=""
                                    />
                                </li>
                                <li><p className="innerTitle">Monthly Accounting : </p>
                                    <img
                                        src={require('../../../materials/wrong.png')}
                                        alt=""
                                    />
                                </li>
                                <li><p className="innerTitle">SQL Integration : </p>
                                    One Off RM 800 (Optional)
                                </li>
                                <li><p className="innerTitle">Accounting Software : </p>
                                    One Off RM 1,500 (Optional)
                                </li>
                            </ul>
                        </div>
                        <div className="windowsColumns">
                            <ul className="price">
                                <li className="header">
                                    <h1>Pro-Accounting</h1>
                                    <p>Billed Monthly</p>
                                    <br></br>
                                    <p>First 3000 Orders : RM 0.20 / order</p>
                                    <p>Next 7000 Orders : RM 0.15 / order</p>
                                    <p>The rest: RM 0.10 / order</p>
                                    <br></br>
                                    <button onClick={(() => { 
                                        window.open('https://wa.link/9v7riw');
                                    })}>Sign Up</button>
                                </li>
                                <li><p className="innerTitle">Orders : </p>Unlimited</li>
                                <li><p className="innerTitle">Store Quantity : </p>RM 20 / Store</li>
                                {
                                    /*
                                <li><p className="innerTitle">Shipment (Shopee Only) : </p>
                                    <img
                                        src={require('../../../materials/correct.png')}
                                        alt=""
                                    />
                                </li>
                                <li><p className="innerTitle">Inventory Sync : </p>
                                    <img
                                        src={require('../../../materials/correct.png')}
                                        alt=""
                                    />
                                </li>
                                    */
                                }
                                <li><p className="innerTitle">Payment Reconciliation : </p>
                                    <img
                                        src={require('../../../materials/correct.png')}
                                        alt=""
                                    />
                                </li>
                                {/*
                                <li><p className="innerTitle">Multi Chat : </p>
                                    <img
                                        src={require('../../../materials/correct.png')}
                                        alt=""
                                    />
                                </li>
                                */}
                                <li><p className="innerTitle">Invoice Entry : </p>
                                    <img
                                        src={require('../../../materials/correct.png')}
                                        alt=""
                                    />
                                </li>
                                <li><p className="innerTitle">Contra Invoice : </p>
                                    <img
                                        src={require('../../../materials/correct.png')}
                                        alt=""
                                    />
                                </li>
                                <li><p className="innerTitle">Monthly Accounting : </p>
                                    <img
                                        src={require('../../../materials/correct.png')}
                                        alt=""
                                    />
                                </li>
                                <li><p className="innerTitle">SQL Integration : </p>
                                    One Off RM 800 (Optional)
                                </li>
                                <li><p className="innerTitle">Accounting Software : </p>
                                    One Off RM 1,500 (Optional)
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <HomeFooter />
        </div>
    )
}

export default Services;