import "./HomeFooter.scss";


const HomeFooter = () => {

    return (
        <div className="homeFooter">
            <div className="copyright">
                <p>© 2025 TactiBots All Rights Reserved</p>
            </div>
        </div>
    )
}

export default HomeFooter;